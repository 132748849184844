import { stateList } from '../../../common/utils/getStateData';

export const MAX_LISTINGS_PER_PAGE = 30;

export const BREED_OPTIONS = [
  {
    value: '',
    label: 'Designer + Purebred',
  },
  {
    value: 'purebred',
    label: 'Purebred',
  },
  {
    value: 'mixed',
    label: 'Designer',
  },
];

export const DISTANCE_OPTIONS = [
  {
    value: 25,
    label: '25 mi',
  },
  {
    value: 50,
    label: '50 mi',
  },
  {
    value: 100,
    label: '100 mi',
  },
  {
    value: 250,
    label: '250 mi',
  },
];

export const SEARCH_BY_OPTIONS = [
  {
    value: 'everywhere',
    label: 'All Locations',
  },
  {
    value: 'state',
    label: 'Search by State',
  },
  {
    value: 'city',
    label: 'Search by City',
  },
];

export const SEX_OPTIONS = [
  {
    value: 'M,F,N',
    label: 'Male + Female',
  },
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
];

export const SORT_OPTIONS = [
  {
    value: 'default',
    label: 'Default Sorting',
    query: {
      sort_by: 'default',
      sort_dir: 'desc',
    },
  },
  {
    value: 'closest',
    label: 'Closest',
    query: {
      sort_by: 'location',
      sort_dir: 'asc',
    },
  },
  {
    value: 'newest',
    label: 'Newest',
    query: {
      sort_by: 'created_at',
      sort_dir: 'desc',
    },
  },
  {
    value: 'youngest',
    label: 'Youngest',
    query: {
      sort_by: 'birthdate',
      sort_dir: 'desc',
    },
  },
  {
    value: 'oldest',
    label: 'Oldest',
    query: {
      sort_by: 'birthdate',
      sort_dir: 'asc',
    },
  },
  {
    value: 'most_popular',
    label: 'Top Dog',
    query: {
      sort_by: 'views',
      sort_dir: 'desc',
    },
  },
  {
    value: 'price_low',
    label: 'Price Low to High',
    query: {
      sort_by: 'price',
      sort_dir: 'asc',
    },
  },
  {
    value: 'price_high',
    label: 'Price High to Low',
    query: {
      sort_by: 'price',
      sort_dir: 'desc',
    },
  },
  {
    value: 'least_popular',
    label: 'Underdog',
    query: {
      sort_by: 'views',
      sort_dir: 'asc',
    },
  },
  {
    value: 'verified',
    label: 'ID Verified First',
    query: {
      sort_by: 'verified',
      sort_dir: 'desc',
    },
  },
];

export const STATE_OPTIONS = stateList.map(s => ({
  label: s.state,
  value: s.short,
}));

// FORM VALUE FORMAT
export const defaults = {
  advanced: false,
  age: { min: 0, max: 18 },
  breed: [],
  city: '',
  distance: 100,
  formatted_breed: [],
  formatted_location: null,
  page: 0,
  page_size: MAX_LISTINGS_PER_PAGE,
  price: { min: 100, max: 5000 },
  premiers_only: 'false',
  saved_search: false,
  sex: 'M,F,N',
  show_matchmaker: false,
  slug: [],
  sort: 'default',
  sort_by: 'default',
  sort_dir: 'desc',
  sort_breed: '',
  search_by: 'everywhere',
  state: '',
};

const getLocationParts = (query = {}, user, geoCompleteTouched) => {
  // use query location if present
  if (query.search_by === 'city' || query.search_by === 'state') {
    if (query.city) {
      return {
        city: query.city,
        formatted_location: query.formatted_location,
        state: query.state,
      };
    }

    const state = STATE_OPTIONS.find(s => query?.state?.toLowerCase() === s.value.toLowerCase());

    return {
      city: '',
      formatted_location: null,
      state: state ? state.value : '',
    };
  }

  // TODO: figure out how to get this to work with the constant redirects
  // fall back to user location if signed in and `formatted_location` is present
  // if (
  //   user?.get('formatted_location') &&
  //   !geoCompleteTouched &&
  //   !query.saved_search &&
  //   !query.show_matchmaker // don't default to user location for matchmaker or saved search
  // ) {
  //   const formattedLocation = user?.get('formatted_location');
  //   const [city, state] = formattedLocation?.split(', ');
  //   return {
  //     city: city.toLowerCase(),
  //     formatted_location: formattedLocation,
  //     state: state.toUpperCase(),
  //   };
  // }

  return {
    city: '',
    formatted_location: null,
    state: '',
  };
};

function getSort(query) {
  if (query.sort === 'closest') {
    return query?.city ? 'closest' : 'newest';
  }

  const matchedSortOption = SORT_OPTIONS.find(option => option.value === query.sort);

  return matchedSortOption ? matchedSortOption.value : 'default';
}

export const mapQueryToFormValues = (query = {}, user, geoCompleteTouched) => {
  const formValues = { ...defaults, ...query, sort: getSort(query) };
  const isAdvanced = Boolean(formValues.advanced);

  // convert age
  if (Array.isArray(formValues.age)) {
    formValues.age = { min: formValues.age[0], max: formValues.age[1] };
  }

  // convert breeds
  if (formValues.breed && formValues.formatted_breed) {
    if (Array.isArray(formValues.breed) && Array.isArray(formValues.formatted_breed)) {
      if (isAdvanced) {
        formValues.breed = formValues.breed.map(breed => parseInt(breed, 10));
        formValues.formatted_breed = formValues.breed.map((_, index) => formValues.formatted_breed[index]);
      } else {
        formValues.breed = formValues.breed.length ? [formValues.breed[0]] : [];
        formValues.formatted_breed = formValues.formatted_breed.length ? [formValues.formatted_breed[0]] : [];
      }
    } else {
      formValues.breed = [parseInt(formValues.breed, 10)];
      formValues.formatted_breed = [formValues.formatted_breed];
    }
  }

  // convert distance
  if (query.distance) {
    formValues.distance = parseInt(formValues.distance, 10);
  }

  // convert location
  const { city, formatted_location, state } = getLocationParts(query, user, geoCompleteTouched);
  formValues.city = city;
  formValues.formatted_location = formatted_location;
  formValues.state = state;

  // convert page info
  formValues.page = parseInt(formValues.page, 10);
  formValues.page_size = parseInt(formValues.page_size, 10);

  // convert prices
  if (Array.isArray(formValues.price)) {
    formValues.price = { min: formValues.price[0], max: formValues.price[1] };
  }

  // convert sex
  if (Array.isArray(formValues.sex)) {
    formValues.sex = formValues.sex.join(',');
  }

  // convert breed type
  const sortBreed = BREED_OPTIONS.find(option => option.value === query.sort_breed);
  formValues.sort_breed = sortBreed ? sortBreed.value : '';

  // convert search_by
  const searchBy = SEARCH_BY_OPTIONS.find(option => option.value === query.search_by);
  formValues.search_by = searchBy ? searchBy.value : 'everywhere';

  return formValues;
};

export const getSortOptions = query => {
  let sortOptions = [...SORT_OPTIONS];

  if (!query.city) {
    const closestIndex = SORT_OPTIONS.findIndex(option => option.value === 'closest');
    if (closestIndex > -1) {
      sortOptions.splice(closestIndex, 1);
    }
  }

  return sortOptions;
};

export const breedMatchRefineResult = obj => {
  if (Object.keys.length === 0) {
    return {};
  }
  const size = obj.size_range;
  const size_min = size.min;
  const size_max = size.max;
  delete obj.size_range;
  delete obj.formatted_location;
  delete obj.location;
  return { ...obj, ...{ size_min, size_max } };
};

export const mapFormValuesToQuery = (values = {}) => {
  const matchedSortOption = SORT_OPTIONS.find(option => option.value === values.sort) || {};
  const query = { ...values, ...matchedSortOption.query, sort_breed: values.sort_breed };

  query.age = [values.age.min, values.age.max];
  query.distance = `${values.distance}mi`;
  query.price = Array.isArray(query.price)
    ? query.price
    : [parseInt(query.price.min, 10), parseInt(query.price.max, 10)];

  if (values.breed && values.formatted_breed) {
    query.breed = values.breed;
    query.formatted_breed = values.formatted_breed;
  } else {
    delete query.breed;
    delete query.formatted_breed;
  }

  if (!values.city) {
    delete query.city;
  }

  if (!values.formatted_location) {
    delete query.formatted_location;
  }

  if (Array.isArray(values.sex)) {
    query.sex = values.sex;
  } else {
    query.sex = values.sex.split(',');
  }

  if (!values.state) {
    delete query.state;
  }

  if (query.search_by === 'state') {
    delete query.city;
    delete query.formatted_location;
  }

  return query;
};
