import React from 'react';
import { connect as felaConnect } from 'react-fela';

class ContactHeadline extends React.Component {
  render() {
    const { styles } = this.props;

    return (
      <div className={styles.contactHeadlineContainer}>
        <h1 className={styles.testingtitle}>Contact Us</h1>
        <p>
          For faster service, please contact us with the email address linked to your Puppies.com account and only
          submit one message per issue.
        </p>
      </div>
    );
  }
}

const styles = props => ({
  contactHeadlineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '30px 0 60px',

    '> h1': {
      fontFamily: props.theme.typography.sansAlt,
      fontWeight: props.theme.typography.sansAltBold,
      fontSize: '35px',
      letterSpacing: '0.75px',
      lineHeight: '42px',
      marginBottom: '10px',
    },
    '> p': {
      fontSize: '16px',
      lineHeight: '22px',
      marginBottom: '4px',

      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
});

export default felaConnect(styles)(ContactHeadline);
