import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { NavLink, Link, withRouter } from 'react-router-dom';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonPlain, StartTrialModal } from '../';
import MobileMenu from './MobileMenu';
import { IconMenu, ErrorBar } from '../';
import Icon from '../icons/icon';
import theme from '../../../theme';

class TopNav extends Component {
  state = { showTrialModal: false };

  userInformationLinks = userVM => {
    const isMember = userVM.get('isMember');

    return [
      {
        name: 'Edit Profile',
        to: '/account/my-profile',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'My Listings',
        to: '/listings',
        onMobile: false,
        enabled: isMember,
      },
      {
        name: 'Message Center',
        to: '/messages',
        onMobile: false,
        tip: 'messages-tip',
        enabled: true,
      },
      {
        name: 'My Favorites',
        to: '/favorites',
        onMobile: false,
        enabled: true,
      },
      {
        name: 'Membership & Billing',
        to: '/account/membership',
        onMobile: true,
        enabled: isMember,
      },
      {
        name: 'My Testimonials',
        to: isMember ? '/feedback-manager/received' : '/feedback-manager/left',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Saved Searches',
        to: '/saved-searches',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Verifications',
        to: '/account/verifications',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Change Password',
        to: '/account/change-password',
        onMobile: true,
        enabled: true,
      },
      {
        name: 'Notification Settings',
        to: '/account/notification-settings',
        onMobile: true,
        enabled: true,
      },
    ];
  };

  handleTrialStart = () => {
    const { history, isLoggedIn } = this.props;
    this.setState({ showTrialModal: false });
    let destination = '';
    if (isLoggedIn) {
      destination = '/account/my-profile';
    } else {
      destination = '/sign-up';
    }
    history.push({ pathname: destination, state: { purchaseType: 'trial' } });
  };

  navLinks = () => {
    const { user, userVM } = this.props;
    const isMember = userVM.get('isMember');
    return [
      {
        name: 'Find A Puppy',
        to: '/find-a-puppy',
      },
      {
        name: 'Breed Matchmaker',
        to: '/breed-matchmaker',
      },
      {
        name: 'Blog',
        to: '/blog',
      },
      {
        name: 'Breeds',
        to: '/breeds',
      },
      {
        name: 'Store',
        to: 'https://puppies.com/store',
      },
      {
        name: 'FAQ',
        to: 'https://help.puppies.com',
      },
      {
        name: user && isMember ? 'My Listings' : 'List A Puppy',
        to: '/listings',
        onClick: user && isMember ? null : () => this.setState({ showTrialModal: true }),
      },
    ];
  };

  renderNavLinkIcons = size => {
    const { messageCount, styles } = this.props;
    return (
      <div className={styles.iconNavLinks}>
        <NavLink
          to={'/messages'}
          className={styles.iconNavLink}
          activeClassName={styles.iconNavLinkActive}
          id="messages-tip"
        >
          <Icon
            icon="Envelope"
            size={size}
            fill={theme.colors.brown}
            notifications={messageCount}
            label="unread messages"
          />
        </NavLink>
        <NavLink
          to={'/favorites'}
          className={styles.iconNavLink}
          activeClassName={styles.iconNavLinkActive}
          isActive={(match, location) => location.pathname.indexOf('/favorites') === 0}
          id="listings-tip"
        >
          <Icon icon="Heart" size={size} fill={theme.colors.brown} />
        </NavLink>
      </div>
    );
  };

  navLinksMobile = () => {
    const { user, messageCount, userVM } = this.props;
    const isNonMember = !userVM.get('isMember');
    let links = [
      {
        name: 'Find A Puppy',
        to: '/find-a-puppy',
      },
      {
        name: 'Breed Matchmaker',
        to: '/breed-matchmaker',
      },
      {
        name: user && !isNonMember ? 'My Listings' : 'List A Puppy',
        to: '/listings',
        onClick: user && !isNonMember ? null : () => this.setState({ showTrialModal: true }),
      },
      {
        name: (
          <span>
            Message Center{' '}
            {messageCount > 0 && (
              <span
                style={{
                  display: 'inline-block',
                  minWidth: '30px',
                  textAlign: 'center',
                  backgroundColor: theme.colors.darkerRed,
                  color: theme.colors.white,
                  borderRadius: '100%',
                  padding: '4px',
                }}
              >
                {messageCount}
              </span>
            )}
          </span>
        ),
        to: '/messages',
        tip: 'messages-tip',
      },
      {
        name: 'Store',
        to: 'https://puppies.com/store',
      },
      {
        name: 'My Favorites',
        to: '/favorites',
      },
      { name: 'expandableMenuSlot' },
      {
        name: 'Blog',
        to: '/blog',
      },
      {
        name: 'Breeds',
        to: '/breeds',
      },
      {
        name: 'FAQ',
        to: 'https://help.puppies.com',
      },
    ];

    if (user) {
      links.push({
        name: 'Sign Out',
        to: '/logout',
      });
    }
    return links;
  };

  renderUserInformation = () => {
    const { isLoggedIn, styles, userVM } = this.props;
    if (!isLoggedIn) {
      return (
        <div className={styles.userInfo}>
          <Link className={styles.userInfoLink} to={'/sign-up'}>
            <span>Sign Up</span>
          </Link>
          <Link className={styles.userInfoLink} to={'/sign-in'}>
            <span>Sign In</span>
          </Link>
        </div>
      );
    }

    return (
      <div className={styles.userInfo}>
        {this.renderNavLinkIcons(16.65)}
        <span id="main-dropdown-menu">
          <IconMenu icon={<Icon icon="Menu" fill={theme.colors.lightBrown} size={40} />}>
            {this.userInformationLinks(userVM).map((link, i) => {
              if (!link.enabled) {
                return null;
              }

              if (link.onClick) {
                return (
                  <div onClick={link.onClick} key={i} className={styles.userInfoLinkDiv}>
                    {link.name}
                  </div>
                );
              }

              return (
                <Link key={i} to={link.to}>
                  {link.name}
                </Link>
              );
            })}
          </IconMenu>
        </span>
      </div>
    );
  };

  renderLogo() {
    return (
      <svg width="149" height="28" viewBox="0 0 184 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.1208 26.9876C28.6421 26.9876 28.2701 26.615 28.2701 26.1351V9.18498C28.2701 8.70546 28.6421 8.33246 29.1208 8.33246H35.4211C39.382 8.33246 41.8273 10.6774 41.8273 14.382V15.5812C41.8273 19.2857 39.382 21.6311 35.4211 21.6311H31.9652V26.1351C31.9652 26.615 31.5931 26.9876 31.1411 26.9876H29.1208V26.9876ZM31.9652 18.6199H35.4211C37.1756 18.6199 38.1326 17.447 38.1326 15.2616V14.7019C38.1326 12.5169 37.1756 11.3708 35.4211 11.3708H31.9652V18.6199V18.6199Z"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9025 22.1908V12.9162C43.9025 12.4367 44.3012 12.0637 44.7528 12.0637H46.5872C47.0655 12.0637 47.438 12.4367 47.438 12.9162V21.8712C47.438 23.5763 47.8363 24.3227 49.3516 24.3227C50.3619 24.3227 51.3189 23.6832 51.9834 22.9105V12.9162C51.9834 12.4367 52.3555 12.0637 52.8342 12.0637H54.6682C55.1203 12.0637 55.519 12.4367 55.519 12.9162V26.1351C55.519 26.615 55.1203 26.9876 54.6682 26.9876H53.2063C52.728 26.9876 52.3292 26.615 52.2493 26.1351L52.2226 25.5758C51.2656 26.5883 49.9632 27.2545 48.5008 27.2545C45.0186 27.2545 43.9025 25.4158 43.9025 22.1908"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.9758 30.9854C58.5241 30.9854 58.125 30.6128 58.125 30.1329V12.9163C58.125 12.4367 58.5241 12.0637 58.9758 12.0637H60.3848C60.8631 12.0637 61.2885 12.4367 61.3685 12.8895L61.4218 13.476C62.2721 12.57 63.7074 11.7972 65.1965 11.7972C68.7049 11.7972 70.0611 13.6627 70.0611 17.1805V21.8713C70.0611 25.3891 68.7049 27.2545 65.1965 27.2545C63.8408 27.2545 62.4846 26.6418 61.6609 25.8152V30.1329C61.6609 30.6128 61.2885 30.9854 60.8102 30.9854H58.9758V30.9854ZM61.6609 16.1409V22.9105C62.3255 23.6832 63.415 24.3227 64.4253 24.3227C65.9672 24.3227 66.5255 23.4969 66.5255 21.6048V17.447C66.5255 15.5548 65.9672 14.7286 64.4253 14.7286C63.415 14.7286 62.3255 15.3681 61.6609 16.1409V16.1409Z"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4383 30.9854C72.9862 30.9854 72.5875 30.6128 72.5875 30.1329V12.9163C72.5875 12.4367 72.9862 12.0637 73.4383 12.0637H74.8469C75.3252 12.0637 75.751 12.4367 75.8306 12.8895L75.8839 13.476C76.7346 12.57 78.1699 11.7972 79.6586 11.7972C83.1674 11.7972 84.5231 13.6627 84.5231 17.1805V21.8713C84.5231 25.3891 83.1674 27.2545 79.6586 27.2545C78.3028 27.2545 76.9471 26.6418 76.123 25.8152V30.1329C76.123 30.6128 75.751 30.9854 75.2723 30.9854H73.4383V30.9854ZM76.123 16.1409V22.9105C76.7876 23.6832 77.8775 24.3227 78.8878 24.3227C80.4293 24.3227 80.9876 23.4969 80.9876 21.6048V17.447C80.9876 15.5548 80.4293 14.7286 78.8878 14.7286C77.8775 14.7286 76.7876 15.3681 76.123 16.1409V16.1409Z"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.4319 26.9876C87.9803 26.9876 87.5812 26.615 87.5812 26.1351V14.7019L87.1295 14.6752C86.6775 14.6752 86.4117 14.3289 86.4117 13.8761V12.8365C86.4117 12.3837 86.7308 12.0637 87.1829 12.0637H90.2664C90.7446 12.0637 91.1171 12.4367 91.1171 12.9163V26.1351C91.1171 26.615 90.7446 26.9876 90.2664 26.9876H88.4319V26.9876ZM89.2031 10.6244C88.007 10.6244 87.2091 10.1448 87.2091 8.83912C87.2091 7.50625 88.007 7 89.2031 7C90.4522 7 91.2767 7.50625 91.2767 8.83912C91.2767 10.1448 90.4522 10.6244 89.2031 10.6244V10.6244Z"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.183 27.2545C96.4346 27.2545 94.1215 25.1226 94.1215 21.6311V17.4207C94.1215 13.9288 96.4346 11.7972 100.183 11.7972C103.877 11.7972 106.19 13.9288 106.19 17.4207V19.3396C106.19 19.7924 105.818 20.1654 105.366 20.1654H97.6574V21.5246C97.6574 23.55 98.5611 24.456 100.183 24.456C101.963 24.456 102.601 23.3637 102.601 22.5905C102.601 22.1106 102.867 21.8713 103.372 21.8713H105.366C105.818 21.8713 106.11 22.1908 106.11 22.6703C106.11 24.6961 104.064 27.2545 100.183 27.2545V27.2545ZM97.6307 17.5803V17.9533L102.681 17.8735V17.5272C102.681 15.6083 101.83 14.5958 100.183 14.5958C98.5611 14.5958 97.6307 15.5812 97.6307 17.5803V17.5803Z"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.113 24.6427C115.681 24.6427 116.425 23.8695 116.425 22.804C116.425 21.4181 114.884 21.0183 112.784 20.6721C110.391 20.2456 108.291 19.1529 108.291 16.4341V16.0348C108.291 13.5023 110.152 11.7709 113.9 11.7709C117.808 11.7709 119.509 14.0357 119.509 16.0611C119.509 16.5147 119.217 16.8075 118.765 16.8075H116.984C116.506 16.8075 116.213 16.5941 116.213 16.1146C116.213 15.4212 115.575 14.4087 113.9 14.4087C112.438 14.4087 111.827 15.2086 111.827 16.2478C111.827 17.5001 112.917 17.9002 114.937 18.2732C117.542 18.7532 119.908 19.6591 119.908 22.4306V22.9372C119.908 25.4426 117.861 27.2809 114.113 27.2809C110.285 27.2809 108.105 25.2291 108.105 22.9635C108.105 22.5108 108.398 22.2175 108.85 22.2175H110.657C111.136 22.2175 111.455 22.4306 111.455 22.9105C111.455 23.6832 112.412 24.6427 114.113 24.6427"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.896 27.3343C122.7 27.3343 121.903 26.7215 121.903 25.4425C121.903 24.0834 122.7 23.4969 123.896 23.4969C125.145 23.4969 125.943 24.0834 125.943 25.4425C125.943 26.7215 125.145 27.3343 123.896 27.3343"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.343 27.2278C130.595 27.2278 128.283 25.1226 128.283 21.6311V17.4207C128.283 13.9287 130.595 11.8235 134.343 11.8235C138.118 11.8235 140.298 13.8494 140.298 16.8075C140.298 17.2607 139.952 17.6333 139.5 17.6333H137.586C137.081 17.6333 136.789 17.3138 136.789 16.8075C136.789 15.5812 136.018 14.6484 134.343 14.6484C132.722 14.6484 131.818 15.7144 131.818 17.7402V21.3115C131.818 23.3373 132.722 24.4029 134.343 24.4029C136.045 24.4029 136.789 23.443 136.789 22.2438C136.789 21.738 137.081 21.418 137.586 21.418H139.474C139.952 21.418 140.298 21.7643 140.298 22.2438C140.298 25.0959 138.118 27.2278 134.343 27.2278"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.001 21.6311V17.4207C142.001 13.9288 144.313 11.7972 148.061 11.7972C151.756 11.7972 154.069 13.9288 154.069 17.4207V21.6311C154.069 25.1226 151.756 27.2545 148.061 27.2545C144.313 27.2545 142.001 25.1226 142.001 21.6311V21.6311ZM145.536 17.7139V21.3379C145.536 23.3637 146.44 24.4296 148.061 24.4296C149.657 24.4296 150.534 23.3637 150.534 21.3379V17.7139C150.534 15.6881 149.657 14.6221 148.061 14.6221C146.44 14.6221 145.536 15.6881 145.536 17.7139V17.7139Z"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.861 26.9876C156.41 26.9876 156.011 26.615 156.011 26.1351V12.9163C156.011 12.4367 156.41 12.0637 156.861 12.0637H158.297C158.776 12.0637 159.201 12.4367 159.254 12.9163L159.307 13.476C160.264 12.4631 161.461 11.7972 162.869 11.7972C164.943 11.7972 166.086 12.5169 166.724 13.7161C167.707 12.5963 169.01 11.7972 170.499 11.7972C173.981 11.7972 174.911 13.6359 174.911 16.8606V26.1351C174.911 26.615 174.539 26.9876 174.06 26.9876H172.226C171.748 26.9876 171.376 26.615 171.376 26.1351V17.1805C171.376 15.4751 171.11 14.7286 169.648 14.7286C168.664 14.7286 167.814 15.3151 167.229 16.0076C167.282 16.3276 167.282 16.701 167.282 17.1275V26.1351C167.282 26.615 166.91 26.9876 166.458 26.9876H164.624C164.172 26.9876 163.773 26.615 163.773 26.1351V17.2607C163.773 15.5548 163.48 14.7286 162.018 14.7286C161.009 14.7286 160.158 15.3949 159.547 16.1409V26.1351C159.547 26.615 159.174 26.9876 158.696 26.9876H156.861"
          fill="#FA6125"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.18694 27.9305C4.16356 27.1656 4.32969 26.3127 4.45357 25.5346C4.94376 22.4465 6.35732 19.7109 8.47437 18.1033C7.44025 17.5473 6.68712 16.4348 6.14935 14.8046C5.10784 11.6442 6.28471 8.45902 9.43629 7.41485C12.329 6.45581 14.5663 7.23308 15.7202 9.80874C15.888 9.78366 16.0647 9.76474 16.2547 9.75569C16.3703 9.44273 16.6714 9.22107 17.0238 9.22806C17.468 9.23793 17.8212 9.60682 17.8118 10.053C17.8102 10.1484 17.7884 10.2377 17.7572 10.322C18.7963 11.5002 19.6733 14.3708 15.8666 17.2561C17.8504 18.4409 18.2638 21.2399 20.1947 25.7842C21.847 29.6722 22.6879 30.3738 20.9609 30.3972C19.6438 30.4149 17.0915 24.9522 17.0915 24.9522C17.2851 28.4306 14.6122 28.7752 14.6122 28.7752C18.7897 28.7288 18.1194 30.5996 18.1194 30.5996C16.4643 30.8994 14.6651 31.0458 12.7852 31.0059C10.6858 30.9619 7.93167 30.6238 6.32819 30.2414C4.4306 30.345 0.580443 30.0509 0.620643 25.9536C0.620643 25.9536 2.38861 28.1983 4.18694 27.9305"
          fill="#1AB9C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.87513 6.75439C6.78023 7.44817 5.56316 9.09153 5.28545 11.0417C5.15788 11.1877 5.02662 11.3296 4.90355 11.478C3.26931 10.1254 1.63424 8.77281 0 7.41938C1.31183 7.15289 2.60519 7.00649 3.86369 6.96865C3.95804 5.71022 4.16109 4.42424 4.48023 3.12592C5.81174 3.17856 7.10142 3.33895 8.33325 3.5968C8.71761 2.39554 9.21683 1.19016 9.83582 0C10.6796 1.94974 11.5234 3.89907 12.3672 5.84882C11.9972 6.01044 11.6325 6.18234 11.2736 6.36494C10.5397 6.34108 9.73737 6.46857 8.87513 6.75439"
          fill="#1AB9C4"
        />
        <path
          d="M178.64 17.55H178.38C178.24 17.55 178.13 17.43 178.13 17.29V13.73C178.13 13.59 178.24 13.48 178.38 13.48H179.7C180.57 13.48 181.12 13.89 181.12 14.7V14.93C181.12 15.49 180.82 15.89 180.33 16.05L181.16 17.33C181.22 17.42 181.15 17.55 181.04 17.55H180.65C180.5 17.55 180.34 17.46 180.27 17.33L179.53 16.15H178.9V17.29C178.9 17.43 178.78 17.55 178.64 17.55ZM178.9 14.13V15.51H179.64C180.1 15.51 180.35 15.33 180.35 14.81C180.35 14.29 180.1 14.13 179.64 14.13H178.9ZM179.57 19.14C177.98 19.14 176.56 18.33 176.56 16.95V14.08C176.56 12.71 177.98 11.9 179.57 11.9C181.16 11.9 182.58 12.71 182.58 14.08V16.95C182.58 18.33 181.16 19.14 179.57 19.14ZM179.57 12.6C178.46 12.6 177.31 13.12 177.31 14.12V16.91C177.31 17.92 178.46 18.43 179.57 18.43C180.69 18.43 181.83 17.92 181.83 16.91V14.12C181.83 13.12 180.69 12.6 179.57 12.6Z"
          fill="#FA6125"
        />
      </svg>
    );
  }

  renderDesktopNav() {
    const { styles, user } = this.props;

    return (
      <div
        className={styles.root + ' ' + styles.desktopRoot}
        style={user && user.get('impersonated_by') && { top: '56px' }}
      >
        <div className={styles.nav}>
          <NavLink to={'/'}>
            <div className={styles.navIcon}>{this.renderLogo()}</div>
          </NavLink>
          <div className={styles.navRight}>
            <div className={styles.navLinks} data-testid="header-menu">
              {this.navLinks().map((link, i) => {
                if (link.onClick) {
                  return (
                    <div className={styles.navLink} key={i} onClick={link.onClick}>
                      {link.name}
                    </div>
                  );
                }

                if (link.to.indexOf('help.') >= 0) {
                  return (
                    <a href={link.to} target="_blank" key={i} rel="noreferrer" className={styles.navLink}>
                      {link.name}
                    </a>
                  );
                }
                if (link.to === 'https://puppies.com/store') {
                  return (
                    <a href={link.to} key={i} rel="noreferrer" className={styles.navLink}>
                      {link.name}
                    </a>
                  );
                }

                return (
                  <NavLink
                    activeClassName={styles.navLinkActive}
                    className={styles.navLink}
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }

                      const matchListingId = matchPath(location.pathname, {
                        path: '/listings/:listingId',
                        exact: true,
                        strict: false,
                      });

                      const listingId = matchListingId && matchListingId.params && matchListingId.params.listingId;

                      // Check whether user exists and user owns listing
                      if (listingId && user) {
                        if (user.get('listings').find(listing => listing.get('id') === listingId)) {
                          return true;
                        } else {
                          return false;
                        }
                      }

                      return true;
                    }}
                    key={i}
                    to={link.to}
                    id={link.tip}
                  >
                    {link.name}
                  </NavLink>
                );
              })}
            </div>
            {this.renderUserInformation()}
          </div>
        </div>
      </div>
    );
  }

  renderMobileNav() {
    const { styles, isLoggedIn, messageCount, userVM } = this.props;

    return (
      <div className={styles.root + ' ' + styles.mobileRoot}>
        <div className={styles.nav}>
          <NavLink to={'/'}>
            <div className={styles.navIcon}>{this.renderLogo()}</div>
          </NavLink>
          <MobileMenu
            notifications={messageCount}
            isLoggedIn={isLoggedIn}
            showTrialModal={() => this.setState({ showTrialModal: true })}
            links={this.navLinksMobile()}
            expandedMenuLinks={isLoggedIn ? this.userInformationLinks(userVM) : null}
            expandedMenuTitle="Profile & Settings"
          />
        </div>
      </div>
    );
  }

  renderTrialModal = () => {
    const { history, userVM, isLoggedIn } = this.props;
    if (userVM.get('isTrial') && userVM.get('isExpired')) {
      return (
        <Modal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          closeOnEscape
          closeOnOutsideClick
        >
          <ModalHeader title="Trial Expired" />
          <ModalBody>
            Keep your listings active starting at only <strong>$29.99</strong> for 30 days of access, or{' '}
            <strong>$19.99</strong> monthly.
          </ModalBody>
          <ModalFooter
            actions={[
              <ButtonPlain
                type="button"
                variant="red"
                onClick={() => {
                  this.setState({ showTrialModal: false });
                }}
              >
                Cancel
              </ButtonPlain>,
              <Button
                type="button"
                buttonType="round"
                onClick={() =>
                  this.setState({ showTrialModal: false }, () => {
                    history.push('/account/membership');
                  })
                }
              >
                Upgrade Now
              </Button>,
            ]}
          />
        </Modal>
      );
    } else if (userVM.get('isEligibleForTrial') || !isLoggedIn) {
      return (
        <StartTrialModal
          isOpen={this.state.showTrialModal}
          onClose={() => {
            this.setState({ showTrialModal: false });
          }}
          onSubmit={this.handleTrialStart}
          closeOnEscape
          closeOnOutsideClick
        />
      );
    }
    return null;
  };

  render() {
    const { styles, errorBarError } = this.props;
    return (
      <div className={styles.topNavContainer}>
        {this.renderMobileNav()}
        {this.renderDesktopNav()}
        {this.renderTrialModal()}
        <ErrorBar message={errorBarError} />
      </div>
    );
  }
}

TopNav.defaultProps = {
  isSeller: false,
  isLoggedIn: false,
};

TopNav.propTypes = {
  isSeller: PropTypes.bool,
  messageCount: PropTypes.number,
  isLoggedIn: PropTypes.bool,
};

const styles = props => ({
  topNavContainer: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 5,
  },
  root: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '82px',
    width: '100%',
    backgroundColor: props.theme.colors.tan,
    borderBottom: `7px solid ${props.theme.colors.yellow}`,
  },
  desktopRoot: {
    display: 'none',
    [props.theme.breakpoints.desktop]: {
      display: 'flex',
    },
  },
  mobileRoot: {
    display: 'flex',
    [props.theme.breakpoints.desktop]: {
      display: 'none',
    },
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    ...props.theme.globalPadding,
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
  },
  navRight: {
    display: 'flex',
    alignItems: 'center',
  },
  navLinks: {
    display: 'flex',

    '> * + *': {
      marginLeft: '15px',
    },
  },
  navLink: {
    fontSize: '12px',
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltMedium,
    color: props.theme.colors.brown,
    letterSpacing: '1.8px',
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    padding: '4px 12px',
    borderRadius: '14px',
    transition: props.theme.globalTransition,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: `${props.theme.colors.blue} !important`,
      color: `${props.theme.colors.white} !important`,
    },
  },
  navLinkActive: {
    backgroundColor: `${props.theme.colors.blue} !important`,
    color: `${props.theme.colors.white} !important`,
  },
  iconNavLinks: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '6px',
    },
  },
  iconNavLink: {
    padding: '6px',
    cursor: 'pointer',
    borderRadius: '50%',
    ':hover': {
      backgroundColor: `${props.theme.colors.blue} !important`,
      '> span': {
        fill: `${props.theme.colors.white} !important`,
      },
    },
  },
  iconNavLinkActive: {
    backgroundColor: `${props.theme.colors.blue} !important`,
    '> span': {
      fill: `${props.theme.colors.white} !important`,
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '40px',
    '> * + *': {
      marginLeft: '12px',
    },
  },
  userInfoLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: props.theme.colors.blue,
    letterSpacing: '1.25px',
    cursor: 'pointer',
    fontWeight: props.theme.typography.sansBold,
    '> svg': {
      marginRight: '4px',
    },
    '> * + *': {
      marginLeft: '4px',
    },
    ':hover': {
      color: props.theme.colors.darkerBlue,
    },
  },
  userInfoLinkDiv: {
    display: 'flex',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
    cursor: 'pointer',
    padding: '16px 20px',
    '> svg': {
      marginRight: '4px',
    },
    '> * + *': {
      marginLeft: '4px',
    },
    ':hover': {
      backgroundColor: props.theme.colors.darkerBlue,
    },
  },
  icon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navIcon: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  navCrownIcon: {
    marginRight: '5px',
  },
  puppyLogoText: {
    width: '125px !important',
    height: '20px !important',
  },
  iconMenu: {
    padding: '20px',
    fontSize: '12px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
  },
  mobileMenuExpand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0',
    fontSize: '24px',
    color: props.theme.typography.bodyColor,
    textTransform: 'uppercase',
    fontFamily: props.theme.typography.sansAlt,
    cursor: 'pointer',
    '> * + *': {
      marginLeft: '10px',
    },
  },
  messageCount: {
    backgroundColor: props.theme.colors.darkerRed,
    color: props.theme.colors.white,
    borderRadius: '100%',
  },
});

export default withRouter(felaConnect(styles)(TopNav));
