import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import { Redirect } from 'react-router-dom';
import compose from 'lodash/flowRight';
import {
  Button,
  CircleAvatar,
  Icon,
  PuppyGrid,
  SubNav,
  Loading,
  PuppyListHeader,
  TestimonialList,
  NoContentPlaceholder,
  ContentNotFound,
  ContactButton,
  VerifyEmailModal,
  LicenseBadge,
} from '../../common/components';
import generateInitials from '../../common/utils/generateInitials';
import theme from '../../theme';
import queryString from 'query-string';
import removeFromQueryString from '../../common/utils/remove-from-query-string';
import ContactSellerModal from '../../common/components/ContactSellerModal';
import { getProfile, getProfileListings, resetProfile, resetProfileListings } from './redux/actions';
import { resetSendMessageToUser } from '../Messages/redux/actions';

const MAX_COLLAPSED_PUPPIES = 8;

class Profile extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(props.location.search);

    this.state = {
      isCollapsed: true,
      showContactModal: !!search.contact && props.user && props.user.get('email_verified'),
      showVerifyEmailModal: !!search.contact && props.user && !props.user.get('email_verified'),
      requestVerification: !!parseInt(search.verification, 10) && !!props.user,
      testimonialRenders: 0,
    };
  }

  static fetchData(location, match, user) {
    return [getProfile(match.params.profileId), getProfileListings(match.params.profileId)];
  }

  toggleCollapsed = e => {
    this.setState(state => ({ isCollapsed: !state.isCollapsed }));
  };

  componentDidMount() {
    const { location, match, user, dispatch, profile, listings } = this.props;

    if (!profile || !listings) {
      Profile.fetchData(location, match, user).forEach(action => dispatch(action));
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    const search = queryString.parse(this.props.location.search);
    const prevSearch = queryString.parse(prevProps.location.search);

    if (prevSearch.verification !== search.verification) {
      this.setState({ requestVerification: !!parseInt(search.verification, 10) && !!user });
    }

    if (prevSearch.contact !== search.contact) {
      this.setState({
        showContactModal: !!search.contact && user && user.get('email_verified'),
        showVerifyEmailModal: !!search.contact && user && !user.get('email_verified'),
      });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetSendMessageToUser());
    dispatch(resetProfile());
    dispatch(resetProfileListings());
  }

  shouldShowContactModal() {
    const { isUser, profile, sendMessageLoaded } = this.props;
    return !!(!isUser && profile && (!profile.get('conversation') || sendMessageLoaded) && this.state.showContactModal);
  }

  navigateToEditProfile = () => {
    const { history, location } = this.props;
    history.push({
      pathname: `/account/my-profile`,
      state: {
        prevLocation: location,
        backTo: 'Profile',
      },
    });
  };

  showAuthModal = () => {
    const { showAuthenticationModal } = this.props;
    showAuthenticationModal();
  };

  renderToggleCollapsed = () => {
    const { isCollapsed } = this.state;
    const { styles, listings } = this.props;
    if (listings && listings.size && listings.size > MAX_COLLAPSED_PUPPIES) {
      return (
        <Button onClick={this.toggleCollapsed} buttonType="round" minwidth="unset">
          <span className={styles.toggleCollapsedText}>{isCollapsed ? 'Show All' : 'Show Less'}</span>
          {isCollapsed ? <Icon icon="CaretDown" size={18} /> : <Icon icon="CaretUp" size={18} />}
        </Button>
      );
    }
    return null;
  };

  renderAboutMe = () => {
    const { profile, styles } = this.props;
    return (
      <div className={styles.aboutMe}>
        <h1 className={styles.aboutMeMainTitle}>About me</h1>
        {profile.get('bio') ? (
          <p>{profile.get('bio')}</p>
        ) : (
          <h1 className={styles.emptyAboutTitle}>Nothing written yet, stay tuned.</h1>
        )}
      </div>
    );
  };

  renderContactButton = (options = {}) => {
    const { isUser, profile, location, user } = this.props;
    const userIsBlocked = profile?.get('blocked') || false;

    if (userIsBlocked) {
      return (
        <Button buttonType={'round'} disabled={true}>
          Contact Me
        </Button>
      );
    } else {
      return (
        <ContactButton
          conversation={profile.get('conversation')}
          user={user}
          isSelf={isUser}
          location={location}
          backTo="Profile"
          onStartContact={requestVerification => this.setState({ showContactModal: true, requestVerification })}
          showVerifyEmailModal={this.state.showVerifyEmailModal}
          onOpenVerify={() => this.setState({ showVerifyEmailModal: true })}
          isRequestVerificationButton={!!options.requestVerificationButton}
          contextText="CONTACT ME"
        />
      );
    }
  };

  renderContactMe = () => {
    const { profile, styles } = this.props;
    return (
      <div className={styles.contactBox}>
        {this.renderContactButton()}
        {profile.get('formatted_location') && (
          <div className={styles.contactBoxLocation}>{profile.get('formatted_location')}</div>
        )}
      </div>
    );
  };

  renderMemberAge = () => {
    const { accountAge, styles } = this.props;
    return (
      <div className={styles.memberFor}>
        A member for <span>{accountAge}</span>
      </div>
    );
  };

  renderVerifiedCheck = (label, isVerified) => {
    const { isMobile, styles } = this.props;

    return (
      <div className={styles.verificationTag}>
        {isVerified ? (
          <Icon icon="CheckCircle" fill={theme.colors.green} size={isMobile ? 36 : 18} />
        ) : (
          <Icon icon="CircleX" fill={theme.colors.gray} size={isMobile ? 36 : 18} />
        )}
        <span>
          {label} {isVerified ? null : <span style={{ fontWeight: 'bold' }}>Not</span>} Verified
        </span>
      </div>
    );
  };

  renderVerificationData = () => {
    const { profile, styles } = this.props;
    return (
      <div className={styles.sellerDetails}>
        <div className={styles.verificationsLeft}>
          <CircleAvatar
            size={150}
            initials={generateInitials(profile.get('last_initial'), profile.get('first_name'))}
            verified={profile.get('id_verified')}
            photo={profile.get('photo')}
            alt={'profile'}
          />
          <div className={styles.sellerInfo}>
            <h1 aria-label="Profile Name">
              {profile.get('first_name').replace(/^\w/, c => c.toUpperCase())} {profile.get('last_initial')}
            </h1>
            <div className={styles.verifications}>
              {this.renderVerifiedCheck('ID', profile.get('id_verified'))}
              {this.renderVerifiedCheck('Email', profile.get('email_verified'))}
              {this.renderVerifiedCheck('Phone', profile.get('phone_number_verified'))}
            </div>
            <div className={styles.badges}>
              {this.renderMemberAge()}
              <LicenseBadge inline type="federal" user={profile} />
              <LicenseBadge inline type="state" user={profile} />
            </div>
          </div>
        </div>
        {this.renderContactMe()}
      </div>
    );
  };

  renderListings = () => {
    const { listings, location, total, profile } = this.props;
    if (listings && listings.size) {
      const listingsToDisplay = listings;
      return (
        <div>
          <PuppyGrid
            total={total}
            header={
              <PuppyListHeader total={total} totalLabel="available now" actions={[this.renderToggleCollapsed()]} />
            }
            listings={listingsToDisplay}
            isPremier={profile.getIn(['membership', 'plan']) === 'premier'}
            location={location}
            noAds
          />
        </div>
      );
    }
    return <NoContentPlaceholder icon="PuppyOutline" globalPadding />;
  };

  renderSelfEdit() {
    const { isUser, styles } = this.props;

    return (
      <SubNav
        titleText={isUser ? `This is Your Profile` : null}
        profile
        childrenClassName={styles.subnavChildren}
        helperText={isUser ? `This is what your seller profile looks like to others.` : null}
        headerRightChildren={
          isUser ? (
            <Button
              buttonType="roundSmall"
              onClick={this.navigateToEditProfile}
              iconLeft={<Icon icon="Pencil" size={16} fill={theme.colors.blue} />}
            >
              Edit
            </Button>
          ) : null
        }
      />
    );
  }

  render404(message = "Sorry, we couldn't find the page you were looking for.") {
    return <ContentNotFound icon="PuppyOutline" to="/find-a-puppy" buttonText="Find A Puppy" title={message} />;
  }

  render() {
    const { profile, loading, styles, location, error, history, user } = this.props;
    const { showVerifyEmailModal } = this.state;

    if (loading) {
      return <Loading dark center />;
    }

    if (!profile || error) {
      if (error && error.get('statusCode') === 403) {
        return this.render404(error.get('message'));
      } else {
        return this.render404();
      }
    }

    if (
      profile.get('account_status') === 'Active' &&
      profile.getIn(['membership', 'plan']) === 'premier' &&
      profile.get('premierProfile')
    ) {
      return (
        <Redirect
          to={{
            pathname: `/premier/${profile.getIn(['premierProfile', 'slug'])}`,
            state: {
              prevLocation: location.state && location.state.prevLocation,
              backTo: location.state && location.state.backTo,
            },
          }}
        />
      );
    }

    const query = queryString.parse(location.search);
    const viewerId = user && user.get('id') ? user.get('id') : null;
    const premier = profile.setIn(['user', 'id'], profile.get('id'));
    const isSelf = !!(user && premier.getIn(['user', 'id']) === user.get('id'));

    return (
      <div className={styles.container}>
        <div>{this.renderSelfEdit()}</div>
        <div className={styles.wrapper}>
          <div className={styles.profileTop}>{this.renderVerificationData()}</div>
          <div className={styles.aboutMeWrapper}>{this.renderAboutMe()}</div>
          <div className={styles.listingsWrapper}>{this.renderListings()}</div>
          <TestimonialList
            include="for"
            userId={profile.get('id')}
            viewerId={viewerId}
            page={parseInt(query.page, 10) || 0}
            pageSize={parseInt(query.page_size, 10) || 16}
            className={styles.testimonials}
            showAuthModal={() => this.showAuthModal()}
            premier={premier}
            isEmailVerified={user ? user.get('email_verified') : null}
            showVerifyEmailModal={showVerifyEmailModal}
            canLeaveTestimonial={profile.get('canLeaveTestimonial')}
            showHidden={false}
            showFull={true}
            handleEmailIsNotVerified={() =>
              this.setState({
                showVerifyEmailModal: true,
              })
            }
          />
          <ContactSellerModal
            requestVerification={this.state.requestVerification}
            isOpen={this.shouldShowContactModal()}
            seller={profile}
            onClose={() =>
              this.setState({ showContactModal: false, requestVerification: false }, () => {
                history.replace(
                  location.pathname + removeFromQueryString(location.search, ['contact', 'verification']),
                  {
                    ...location.state,
                  }
                );
              })
            }
          />
          {user ? (
            <VerifyEmailModal
              isOpen={!isSelf && showVerifyEmailModal}
              onClose={() => this.setState({ showVerifyEmailModal: false })}
              user={user}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const styles = {
  container: props => ({
    width: '100%',
    color: props.theme.colors.brown,
  }),
  wrapper: props => ({
    width: '100%',
    padding: '32px 0 60px',
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
  }),

  sellerDetails: props => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 0,
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  badges: props => ({
    display: 'flex',
    flexDirection: 'row',
    margin: '14px auto 0',
    [props.theme.breakpoints.tablet]: {
      margin: '14px 0 0',
    },
  }),
  memberFor: props => ({
    padding: '6px 20px',
    backgroundColor: props.theme.colors.green,
    fontSize: '14px',
    color: props.theme.colors.white,
    textAlign: 'center',
    borderRadius: '100px',
    width: 'fit-content',
    '> span': {
      fontWeight: props.theme.typography.sansBold,
    },
  }),
  aboutMeWrapper: props => ({
    [props.theme.breakpoints.mobile]: {
      ...props.theme.globalPadding,
    },
  }),
  aboutMe: props => ({
    width: '100%',
    padding: '20px 40px',
    backgroundColor: '#F8F6F3',
    marginBottom: '34px',
    '> p': {
      fontSize: '14px',
      lineHeight: '20px',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
    },
    '@media (max-width: 320px)': {
      padding: '20px',
    },
  }),
  aboutMeMainTitle: props => ({
    fontSize: '28px',
    color: props.theme.colors.orange,
    marginBottom: '16px',
  }),

  verificationsLeft: props => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 0,
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
    },
  }),
  sellerInfo: props => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    width: '100%',
    marginTop: '20px',
    ...props.theme.globalPadding,
    '> h1': {
      fontSize: '35px',
      color: props.theme.colors.orange,
      fontWeight: props.theme.typography.sansBold,
      marginBottom: '10px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
      '> span': {
        fontWeight: props.theme.typography.sansBold,
      },
    },
    [props.theme.breakpoints.tablet]: {
      marginLeft: '32px',
      marginTop: 0,
      padding: 0,
      '> h1': {
        textAlign: 'left',
      },
    },
  }),
  verifications: props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 0 10px',
    '& > * + *': {
      marginTop: '10px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      margin: 0,
      '& > * + *': {
        marginTop: 0,
        marginLeft: '24px',
      },
    },
  }),

  verificationTag: props => ({
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    fontFamily: props.theme.typography.sans,
    fontSize: '12px',
  }),
  contactBox: props => ({
    padding: '24px',
    width: '100%',
    borderRadius: 0,
    backgroundColor: props.theme.colors.yellow,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    '& > * + *': {
      marginTop: '16px',
    },
    [props.theme.breakpoints.tablet]: {
      maxWidth: '250px',
      borderRadius: '4px',
      marginTop: 0,
      marginLeft: '32px',
    },
  }),
  contactBoxLocation: props => ({
    fontFamily: props.theme.typography.sans,
    fontSize: '14px',
  }),
  profileTop: props => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    ...props.theme.globalPadding,
    '& > * + *': {
      marginLeft: '24px',
    },
    '@media (max-width: 864px)': {
      padding: 0,
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: '0',
        marginTop: '24px',
      },
    },
  }),
  listingCount: props => ({
    fontFamily: props.theme.typography.sans,
    fontSize: '28px',
    color: props.theme.colors.orange,
    lineHeight: '1.25',
    '> span': {
      fontWeight: props.theme.typography.sansBold,
    },
    '@media (max-width: 864px)': {
      padding: '0 16px 0 0',
    },
    '@media (max-width: 320px)': {
      fontSize: '22px',
    },
  }),
  listingsHeader: props => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '26px',
    '> button': {
      minWidth: 'unset',
      padding: '8px 16px',
    },
    '@media (max-width: 864px)': {
      padding: '0 24px',
    },
  }),
  toggleCollapsedText: props => ({
    marginRight: '4px',
  }),
  emptyAboutTitle: props => ({
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansRegular,
    fontSize: '28px',
    color: props.theme.colors.orange,
    padding: '65px 0',
    textAlign: 'center',
    lineHeight: '1.25',
    '@media (max-width: 320px)': {
      fontSize: '24px',
    },
  }),
  facebook: {
    marginTop: '20px',
  },
  testimonials: props => ({
    ...props.theme.globalPadding,
  }),
  contactSellerBtn: {
    marginLeft: '10px',
    flexShrink: 0,
  },
  subnavChildren: props => ({
    paddingBottom: '16px',
    [props.theme.breakpoints.mobileLarge]: {
      paddingBottom: 0,
    },
  }),
};

export default compose(felaConnect(styles))(Profile);
