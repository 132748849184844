import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';

import ListingForm from '../../common/ListingForm';
import { ButtonPlain, SubNav, Icon, Loading } from '../../../../common/components/';
import theme from '../../../../theme';
import getListingLimit from '../../../../common/utils/listingLimits';
import { getCoatPatterns, getColors, getListingCount } from '../../../../redux/actions';
import { getListing } from '../../redux/actions';
import { Helmet } from 'react-helmet';
class EditListing extends Component {
  static propTypes = {
    coatPatternsLoading: PropTypes.bool.isRequired,
    coatPatterns: PropTypes.array.isRequired,
    getListingLoading: PropTypes.bool.isRequired,
    currentListing: PropTypes.object.isRequired,
    listingCount: PropTypes.number,
  };

  static fetchData(location, match, user) {
    return [getCoatPatterns(), getColors(), getListingCount(), getListing(match.params.listingId)];
  }

  componentDidMount() {
    const { location, match, user, dispatch, coatPatterns, colors, getListingLoaded } = this.props;

    if (!colors.size || !coatPatterns.length || !getListingLoaded.loaded) {
      EditListing.fetchData(location, match, user).forEach(action => dispatch(action));
    } else {
      dispatch(getListing(match.params.listingId));
      dispatch(getListingCount());
    }

    if (window?.freestar) {
      window.freestar.config.disabledProducts = {
        stickyFooter: true,
      };

      window.freestar?.queue.push(function() {
        window.freestar?.deleteStickyFooter();
      });
    }

    const footer = document.getElementById('fs-sticky-footer');
    if (footer) {
      footer.style.display = 'none';
    }
  }

  componentDidUpdate(prevProps) {
    const { user, currentListing, getListingLoading, history } = this.props;
    if (user && currentListing.size && currentListing.get('seller') !== user.get('id') && !getListingLoading) {
      history.replace('/listings');
    }

    if (prevProps.currentListing !== currentListing && currentListing && currentListing.get('status') === 'sold') {
      this.props.history.replace('/listings/sold');
    }
  }

  onDuplicate = () => {
    this.props.history.push(`/listings/duplicate`, { duplicate: this.props.currentListing.toJS() });
  };

  handleDeleteListing = () => {
    this.props.deleteListing().then(res => {
      if (res?.json.success) {
        this.props.history.push('/listings');
      }
    });
  };

  renderSubNav = () => {
    const { location, user, styles } = this.props;
    if (user.get('listings').size) {
      return (
        <SubNav
          helperText="You are editing a listing"
          helperTextIcon={<Icon icon="Pencil" size={20} fill={theme.colors.green} />}
          keepMainHelperText
          headerRightChildren={
            <Link to={(location.state && location.state.fromPath) || '/listings'}>
              <ButtonPlain className={styles.cancelBtn} variant="red">
                Cancel
              </ButtonPlain>
            </Link>
          }
        />
      );
    }
  };

  render() {
    if (
      !this.props.currentListing.get('name') ||
      this.props.currentListing.get('id') !== this.props.match.params.listingId ||
      this.props.coatPatternsLoading ||
      this.props.getListingLoading ||
      this.props.colorsLoading
    ) {
      return <Loading dark center />;
    }
    const { currentListing, user, styles, listingCount, userVM } = this.props;

    const userId = user.get('id');
    return (
      <div className={styles.root}>
        <Helmet>
          <title>Edit Listing - Puppies.com</title>
        </Helmet>
        {this.renderSubNav()}
        <div className={styles.container}>
          <ListingForm
            userId={userId}
            user={user}
            userVM={userVM}
            coatPatterns={this.props.coatPatterns}
            editMode={true}
            listing={currentListing}
            submitAction={this.props.editListing}
            onDuplicate={this.onDuplicate}
            getUploadToken={this.props.getUploadToken}
            colors={this.props.colors}
            deleteImage={this.props.deleteImage}
            handleDeleteListing={this.handleDeleteListing}
            deleteListingLoading={this.props.deleteListingLoading}
            atListingLimit={listingCount > getListingLimit(userVM) - 1}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  root: props => ({
    backgroundColor: props.theme.colors.tan,
  }),
  container: props => ({
    width: '100%',
    ...props.theme.globalPageWrapper,
    display: 'block',
  }),
  link: props => ({
    fontWeight: props.theme.typography.sansBold,
    textDecoration: 'underline',
  }),
  cancelBtn: props => ({
    paddingBottom: 0,
  }),
};

export default felaConnect(styles)(EditListing);
